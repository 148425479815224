import {useState} from "react";

import {CSVLink} from "react-csv";

import DownloadCSV from "../../assets/csv.png";
import DownloadPDF from "../../assets/pdf.png";

import DistributionSummaryTotal from "../distribution-summary-total/distribution-summary-total.component";

import Pagination from "../pagination/pagination.component";

import {formatData, generatePDF} from "./utils";

const DistributionSummary = ({distribution, fromDate, toDate}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    //Get current posts
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = distribution.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber === 0) {
            setCurrentPage(() => currentPage - 1);
        } else if (pageNumber > distribution.length) {
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(pageNumber);
        }
    };
    return (
        <div className="responsive__table__container">
            <div className="tables">
                {distribution.length > 0
                    ? currentItems.map((item, idx) => (
                        <div key={idx}>
                            <div key={`container__${idx}`} className="download__container">
                                <h2
                                    key={item.company || item.agentReportingCode}
                                    className="agent__title"
                                >
                                    {item.company
                                        ? `Company: ${item.company}`
                                        : item.agentReportingCode
                                            ? `Agent: ${item.agentReportingCode}`
                                            : null}
                                </h2>
                                <div>
                                    <CSVLink
                                        key={`csv__link__${
                                            item.company || item.agentReportingCode
                                        }`}
                                        data={formatData(item)}
                                        filename={
                                            item.company !== undefined
                                                ? `${item.company}.csv`
                                                : `${item.agentReportingCode}.csv`
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Download CSV"
                                        trigger="hover"
                                    >
                                        <img
                                            src={DownloadCSV}
                                            alt="CSV"
                                            className="download-icon"
                                        />
                                    </CSVLink>
                                    <span
                                        key={`pdf__link__${
                                            item.company || item.agentReportingCode
                                        }`}
                                        onClick={() => generatePDF(item, fromDate, toDate)}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Download PDF"
                                        trigger="hover"
                                    >
                      <img
                          src={DownloadPDF}
                          alt="PDF"
                          className="download-icon"
                      />
                    </span>
                                </div>
                            </div>
                            <DistributionSummaryTotal
                                key={
                                    idx +
                                    item.spendingAccountsTotal
                                }
                                spendingAccountsTotal={item.spendingAccountsTotal}
                                teladocTotal={item.teladocTotal}
                                corehealthTotal={item.corehealthTotal}
                                total={item.total}
                            />
                        </div>
                    ))
                    : null}
                {distribution.length > itemsPerPage ? (
                    <nav aria-label="Distribution navigation">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={distribution.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </nav>
                ) : null}
            </div>
        </div>
    );
};

export default DistributionSummary;
